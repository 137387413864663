import type { ComponentType } from "react"
import type { ISbComponentType } from "storyblok-js-client"
import type { z } from "zod"

import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import type { Slice } from "~/components/slices/constants"
import type { SliceWithProps } from "~/components/slices/SliceManager"

export function WithSafeSliceData<Output, Input>(
  Component: ComponentType<Output>,
  schema: z.Schema<Output, z.ZodTypeDef, Input>,
  contextError: string,
  throwError = false
) {
  return function (props: SliceWithProps<ISbComponentType<Slice>>) {
    if (!props.component) {
      return null
    }

    const parsedProps = handleZodSafeParse(schema, props, {
      errorContext: contextError,
      throwError,
      triggerException: true,
      locale: props.locale,
      slug: props.slug,
    })

    return parsedProps ? <Component {...parsedProps} /> : null
  }
}
