import { useGetCart as useAbstractGetCart } from "@unlikelystudio/react-ecommerce-hooks"

import { getProductCustomAttributesValue } from "~/lib/shopify/constants"
import useLang from "~/hooks/useLang"
import { applyLocaleToCart } from "~/utils/apply-locale-to-cart"

export default function useGetCart() {
  const { data: cartPayload, isFetched: cartQueryIsComplete } = useAbstractGetCart({
    refetchOnMount: false,
  })
  const lang = useLang()

  const cartArticlesNumber = cartPayload?.lines
    .filter((line) => !getProductCustomAttributesValue(line.attributes, "IS_EMBROIDERY_PRODUCT"))
    .reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.quantity ?? 0)
    }, 0)

  const totalPrice = cartPayload?.cost?.totalAmount?.amount
  const currencyCode = cartPayload?.cost?.totalAmount?.currencyCode
  const isFilledCart = cartPayload && cartPayload.lines.length > 0
  const cartWebUrl = cartPayload?.checkoutUrl ? applyLocaleToCart(cartPayload.checkoutUrl, lang) : ""

  return {
    cart: cartPayload,
    cartArticlesNumber,
    totalPrice,
    isFilledCart,
    cartWebUrl,
    currencyCode,
    cartQueryIsComplete,
  }
}
